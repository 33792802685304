export const validateImage = async (
  result: any,
  maxWidth = 5000,
  maxHeight = 5000,
  minHeight = 200,
  minWidth = 200,
) => {
  return new Promise((resolve) => {
    var image = new Image();
    image.src = result;
    image.alt = 'Test validation';
    image.onload = function () {
      const height = this.height;
      const width = this.width;
      const fitHeight = height >= minHeight && height <= maxHeight;
      const fitWidth = width >= minWidth && width <= maxWidth;
      resolve(fitHeight && fitWidth);
    };

    image.onerror = function (e) {
      resolve(true);
    };
  });
};
export const validateAudio = async (file: any, maxLength = 90) => {
  function computeLength(file) {
    return new Promise((resolve) => {
      var objectURL = URL.createObjectURL(file);
      var mySound = new Audio([objectURL]);
      mySound.addEventListener(
        'canplaythrough',
        () => {
          URL.revokeObjectURL(objectURL);
          resolve({
            file,
            duration: mySound.duration,
          });
        },
        false,
      );
    });
  }
  const length = await computeLength(file);
  return length?.duration <= maxLength;
};
